import React from 'react';
import './LoginPage.scss';
import LoginCard from '../../components/LoginForm/LoginForm';
import Card from '@material-ui/core/Card';
import webtitleImg from '../../assets/images/brand/title.png';
import { IonImg } from '@ionic/react';

const LoginPage: React.FC = () => {
  const modalCloseHandler = () => {};

  return (
    <div className="bg">
      <Card className="card">
        <div className="logo-div">
          <div className="border">
            <IonImg src={webtitleImg} className="logo" />
          </div>
        </div>

        <div className="login-form">
          <div className="mob-logo-div">
            <div className="border">
              <IonImg src={webtitleImg} className="logo" />
            </div>
          </div>
          <LoginCard modalCloseHandler={modalCloseHandler} />
        </div>
      </Card>
    </div>
  );
};

export default LoginPage;
