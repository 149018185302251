import {
  IS_ADMIN_REPORTS_URL,
  IS_ADMIN_RISKMGMT_URL,
  IS_HOUSE_URL,
  TOGGLE_DARK_MODE,
  FETCH_BETTING_CURRENCY_SUCCESS,
  FETCH_BETTING_CURRENCY_FAILED,
  CASINO_GAME_SELECTED,
  SET_LIVESTREAM_URL,
  SET_ALLOWED_CONFIG,
  ENABLE_COMMISSION,
  SET_DOMAIN_CONFIG,
  SET_PERMISSION_CONFIG
} from './commonActionTypes';
import { CommonState } from '../../models/CommonState';
import { CONFIG_PERMISSIONS } from '../../constants/ConfigPermissions';
import { Currency } from '../../models/Currency';

type Action = {
  type: string;
  payload: any;
};

const initialState: CommonState = {
  isAdminReportsUrl: false,
  isAdminRiskMgmtUrl: false,
  isHouseUrl: false,
  prefersDark: true,
  bettingCurrency: Currency.Pts,
  currenciesAllowed: 0,
  selectedCasinoGame: null,
  tvGamesEnabled: false,
  streamUrl: '',
  allowedConfig: CONFIG_PERMISSIONS.sports + CONFIG_PERMISSIONS.casino,
  commissionEnabled: false,
  domainConfig: {
    demoUser: false,
    signup: false,
    whatsapp: false,
    payments: false,
    bonus: false,
    affiliate: false,
  },
  permissionsConfig: {
    name: null,
    sections: [
      {
        name: '',
        enabled: false,
        route: '',
        subParts: [
          {
            name: '',
            enabled: false,
            route: '',
            actions: '',
            buttonConfigs: ''
          }
        ]
      }
    ]
  }
};

const commonReducer = (state = initialState, action: Action): CommonState => {
  switch (action.type) {
    case IS_ADMIN_REPORTS_URL:
      return {
        ...state,
        isAdminReportsUrl: action.payload,
      };
    case IS_ADMIN_RISKMGMT_URL:
      return {
        ...state,
        isAdminRiskMgmtUrl: action.payload,
      };
    case IS_HOUSE_URL:
      return {
        ...state,
        isHouseUrl: action.payload,
      };
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        prefersDark: action.payload,
      };

    case FETCH_BETTING_CURRENCY_SUCCESS:
      return {
        ...state,
        bettingCurrency: action.payload.bettingCurrency,
        currenciesAllowed: action.payload.currenciesAllowed,
      };

    case FETCH_BETTING_CURRENCY_FAILED:
      return {
        ...state,
        bettingCurrency: null,
      };
    case CASINO_GAME_SELECTED:
      return {
        ...state,
        selectedCasinoGame: action.payload,
      };
    case SET_LIVESTREAM_URL:
      return {
        ...state,
        streamUrl: action.payload,
      };
    case SET_ALLOWED_CONFIG:
      return {
        ...state,
        allowedConfig: action.payload,
      };
    case ENABLE_COMMISSION:
      return {
        ...state,
        commissionEnabled: action.payload,
      };
    case SET_DOMAIN_CONFIG:
      return {
        ...state,
        domainConfig: action.payload,
      };
    case SET_PERMISSION_CONFIG:
      return {
        ...state,
        permissionsConfig: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
